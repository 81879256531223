
function Board(props) {
	const { width, height, boardData, updateStartPos, markedCells } = props;

	const boardWidth = width;
	const boardHeight = height;

	return (
		<div className="">
			<div className="break-words">
				<BoardLine
					boardHeight={boardHeight}
					boardWidth={boardWidth}
					board={boardData}
					markedCells={markedCells}
					updateStartPos={updateStartPos}
					boardDetails={(x, y) => { return {}; }} />
			</div>
		</div>
	);
}

export default Board;

function BoardLine(props) {
	const { boardWidth, boardHeight, board, markedCells, updateStartPos } = props;

	return <div>
		<div className="flex">
			<div className="w-8">&nbsp;</div>
			{Array.from({ length: boardWidth }, (_, x) =>
				<div key={`${x}-index`} style={{ width: '100px', marginLeft: x > 0 ? '-1px' : '0px' }} className="text-center">
					{x}
				</div>)}
		</div>
		{Array.from({ length: boardHeight }, (_, y) => {
			return (
				<div key={y.toString()} className="flex items-center relative">
					<div key={`index-${y}`} className="w-8 text-center">
						{y}
					</div>
					{Array.from({ length: boardWidth }, (_, x) => {
						const markedCellDetail = markedCells.find((mc => mc.x === x && mc.y === y));
						return (<div key={`${x}-${y}`} onClick={() => updateStartPos(x, y)} style={{
							position: 'relative',
							width: '100px',
							height: '100px',
							marginLeft: x > 0 ? '-1px' : '0px',
							marginTop: y > 0 ? '-1px' : '0px',
							zIndex: markedCellDetail ? 10 : 5, // so that the border is completly visible for the selected tile
							borderColor: 'rgb(229, 231, 235)'
						}} className="border">
							<Tile images={board[y * boardWidth + x]} />
							{markedCellDetail && <div style={{
								position: 'absolute',
								left: '-4px',
								top: '-4px',
								width: '106px',
								height: '106px',
								borderWidth: 3,
								borderColor: markedCellDetail.color
							}}></div>}
						</div>)
					})}
				</div>)
		})}
	</div>
};

function Tile(props) {
	const { images } = props;

	return <div>
		{images.length === 0 && <span className="absolute text-red-600">Error</span>}
		{images.length > 1 && <span className="absolute">{images.length}</span>}
		{images.map(image => <img key={image} alt={image} className="absolute" src={"/assets/tiles/" + image} />)}
	</div>
}