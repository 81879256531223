import React, { useState } from "react";
import random from './random';

/**
 * @typedef {Object} TileDef
 * @property {String} image - the filename with extension of the image to be used for this tile.
 * @property {AllowedTileConnection} allowedTileConnection - The allowed tile connection in the four different directions.
 */

/**
 * @typedef {Object} AllowedTileConnection
 * @property {Number} top - the allowed connection type in the top direction (towards the upper bound of the screen)
 * @property {Number} right - the allowed connection type in the right direction
 * @property {Number} bottom - the allowed connection type in the bottom direction
 * @property {Number} left - the allowed connection type in the left direction
 */

/**
 * @typedef {Object} BoardTile
 * @property {String} image - the filename with extension of the image to be used for this tile.
 * @property {AllowedTileConnection} allowedTileConnection - The allowed tile connection in the four different directions.
 */

/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback Log
 * @param {string} message
 */

/**
 * @typedef {Object} Logger
 * @property {Log} debug - Fine logging for debugging.
 * @property {Log} info - Log infos
 * @property {Log} error - An error has happened (unrecoverable).
 */


/**
 * @param {TileDef[]} tiles - the tiles to use for the wave collapse
 * @param {Logger} [logger=undefined] - optional logger object
 */
export default function useSimpleWaveCollapse(tiles, logger) {
	const [boardSize, setBoardSize] = useState({ width: 0, height: 0 });
	const [board, setBoard] = React.useState([]);
	const [workItems, setWorkItems] = React.useState([]);
	const [randomGenerator, setRandomGenerator] = useState();

	const log = logger ? logger : {
		debug: (message) => { /*nop*/ },
		info: (message) => { /*nop*/ },
		error: (message) => { /*nop*/ },
	};

	const allTileIds = Array.from({ length: tiles.length }, (_, id) => tiles[id]);

	const initWaveCollapse = (width, height, startX, startY, seed, customCellConfig) => {
		let actualCustomCellConfig = customCellConfig;
		if (!customCellConfig) {
			actualCustomCellConfig = {};
		}

		setBoardSize({ width, height });
		setWorkItems([{ x: startX, y: startY }]);

		let board = [];
		log.debug(`initBoard width: ${width} height: ${height}`);
		for (let y = 0; y < height; y++) {
			for (let x = 0; x < width; x++) {
				let cellTiles = allTileIds;
				const key = x + '-' + y;
				if (actualCustomCellConfig[key]) {
					cellTiles = actualCustomCellConfig[key];
				}

				board.push(cellTiles);
			}
		}
		setBoard(board);
		setRandomGenerator(() => random.randomGenerator(seed));
	};

	const randomBetween = (r, min, max) => Math.floor(r() * (max - min + 1)) + min;

	const collapseNextCell = (steps, breakOnError) => {
		if (workItems.length === 0) {
			return; // nothing to do
		}

		const workItemsCopy = [...workItems];
		const workingBoard = [...board];
		for (let ticks = 0; ticks < steps; ticks++) {
			log.info(`collapseNextCell ${ticks} ${steps}`);
			if (workItemsCopy.length === 0) {
				break; // nothing to do
			}

			const workItem = workItemsCopy.shift();
			const { paused, effectiveNewWorkItems } = doWork(workingBoard, workItemsCopy, workItem, breakOnError);
			workItemsCopy.push(...effectiveNewWorkItems);
			if (paused) {
				break;
			}
		}

		setWorkItems(workItemsCopy);
		setBoard(workingBoard);
	};

	const collapseCellsUntil = (x, y, breakOnError) => {
		if (workItems.length === 0) {
			return; // nothing to do
		}

		const workItemsCopy = [...workItems];
		const workingBoard = [...board];
		let workItem = workItemsCopy.shift();
		let ticks = 0;
		while (workItemsCopy.length >= 0 && (workItem.x !== x || workItem.y !== y) && ticks < 1000) {
			ticks++;

			const { paused, effectiveNewWorkItems } = doWork(workingBoard, workItemsCopy, workItem, breakOnError);
			workItemsCopy.push(...effectiveNewWorkItems);
			if (paused) {
				break;
			}

			// We need to loop once more when workItemsCopy is empty but in this case we cannot take a new workItem, so check for this condition!
			if (workItemsCopy.length === 0) {
				break;
			}

			workItem = workItemsCopy.shift(); // must be at the end of the loop (and because of this we check for length >= instead of > in the loop condition)
		}

		if (workItem.x === x && workItem.y === y) {
			// important we collaps until we reach the pause cell, but do not evaluate it.
			// so we need to readd it at the beginning!
			workItemsCopy.unshift(workItem);
		}

		log.info(`Finished in ${ticks} steps`);
		setWorkItems(workItemsCopy);
		setBoard(workingBoard);
	};

	const collapseCellsToEnd = (breakOnError) => {
		if (workItems.length === 0) {
			return; // nothing to do
		}

		const workItemsCopy = [...workItems];
		const workingBoard = [...board];
		let workItem = workItemsCopy.shift();
		let ticks = 0;
		while (workItemsCopy.length >= 0 && ticks < 1000) {
			ticks++;

			const { paused, effectiveNewWorkItems } = doWork(workingBoard, workItemsCopy, workItem, breakOnError);
			workItemsCopy.push(...effectiveNewWorkItems);
			if (paused) {
				break;
			}

			// We need to loop once more when workItemsCopy is empty but in this case we cannot take a new workItem, so check for this condition!
			if (workItemsCopy.length === 0) {
				break;
			}

			workItem = workItemsCopy.shift(); // must be at the end of the loop (and because of this we check for length >= instead of > in the loop condition)
		}

		log.info(`Finished in ${ticks} steps`);
		setWorkItems(workItemsCopy);
		setBoard(workingBoard);
	};

	const doWork = (workingBoard, workItemsCopy, workItem, breakOnError) => {
		const { newWorkItems, paused } = executeWaveCollapseTick(workingBoard, workItem, breakOnError);
		log.debug('workItemsCopy before', JSON.stringify(workItemsCopy));
		const effectiveNewWorkItems = newWorkItems.filter(w => { log.debug('w', w, 'can add: ', workItemsCopy.length, !workItemsCopy.some(wic => wic.x === w.x && wic.y === w.y)); return !workItemsCopy.some(wic => { log.debug(`wic.x = ${wic.x} wic.y = ${wic.y} w.x = ${w.x} w.y = ${w.y}`); return wic.x === w.x && wic.y === w.y }) });
		log.debug('newWorkItems', JSON.stringify(newWorkItems), 'workItemsCopy', JSON.stringify(workItemsCopy));
		log.debug('effectiveNewWorkItems:', JSON.stringify(effectiveNewWorkItems))

		return { paused, effectiveNewWorkItems };
	}

	/**
	  * @param {Number[][]} workingBoard - the board state.
	* @param {Number} pos - Either the x or y coordinate of the neibouring cell
	* @param {Number} boundary - Either the lowwer/upper bound for the pos coordinate (boardWidth/Height - 1 or 0) depending on the direction of neibouring cell.
	* @param {Boolean} withinBoundsWhenPosIsSmaller - defines if the boundary check is < or >.
	* @param {Number} neibourCellIndex - The index for the neibour cell in workingBoard array.
	* @param {String} neibourCellDirection - The inverse direction pointing back to the main cell (ie when we are checking the cell to the left of the main cell this argument is right).
	* @param {Number} allowedTileConnection - The connection group of the main cell
	* @returns {Object<{tileAllowed: Boolean, addCellToWorkItems: Boolean}>} - Weather our cell can be present next to this neighbour and if this neigbour needs to be visited by the alrogihtm.
	  */
	const checkCellConstraintsWithNeighbour = (workingBoard, pos, boundary, withinBoundsWhenPosIsSmaller, neibourCellIndex, neibourCellDirection, allowedTileConnection) => {
		if ((withinBoundsWhenPosIsSmaller && pos < boundary) || (!withinBoundsWhenPosIsSmaller && pos > boundary)) {
			// We are still inside the regular space of the board
			//const { cellConstraints, cellsToCheckNext: nextCell } = getNeigbourCellConstraintsOrNextCellsToTest(workingBoard, x, y + 1, 'north');
			//southCellConstraints.push(...cellConstraints);
			//cellsToCheckNext.push(...nextCell);
			const neigbourCell = workingBoard[neibourCellIndex];
			return { tileAllowed: neigbourCell.filter(nc => /*tiles[nc]*/nc.allowedTileConnection[neibourCellDirection] === allowedTileConnection).length > 0, addCellToWorkItems: neigbourCell.length > 1 };
		} else if (pos === boundary) {
			// we are at the edge of the board
			log.debug('check against the 0 allowedTileConnection type due to cell beeing on one of the edges pos:', pos, boundary, allowedTileConnection);
			return { tileAllowed: allowedTileConnection === 0, addCellToWorkItems: false };
		}

		return { tileAllowed: false, addCellToWorkItems: false };
	};

	const executeWaveCollapseTick = (workingBoard, workItem, breakOnError) => {
		// Check all the neighbours to get the rules to collapse this cell

		const cellIndex = workItem.y * boardSize.width + workItem.x;
		let cells = workingBoard[cellIndex];
		log.debug(`cellIndex: ${cellIndex} workItem: ${JSON.stringify(workItem)}`, cells);
		if (cells.length === 0) {
			log.debug(`collapseCell ${workItem.x},${workItem.y}: noting to do bail out`);
			return { newWorkItems: [], paused: breakOnError };
		}

		log.debug(`collapseCell ${workItem.x},${workItem.y}: ${JSON.stringify(cells)}`);

		let cellsToCheckNext = {};

		// Check bottom (south) neigbour (x, y+1)
		cells = cells.filter(c => {
			const x = workItem.x;
			const y = workItem.y + 1;
			const { tileAllowed, addCellToWorkItems } = checkCellConstraintsWithNeighbour(workingBoard, y, boardSize.height, true, y * boardSize.width + x, 'top', c.allowedTileConnection.bottom);

			if (addCellToWorkItems) {
				cellsToCheckNext[`${x}-${y}`] = { x, y };
			}

			return tileAllowed;
		});

		log.debug('after filtering with bottom constraints: ', JSON.stringify(cells));

		// Check right (east) neighbour (x+1, y)
		cells = cells.filter(c => {
			const x = workItem.x + 1;
			const y = workItem.y;
			const { tileAllowed, addCellToWorkItems } = checkCellConstraintsWithNeighbour(workingBoard, x, boardSize.width, true, y * boardSize.width + x, 'left', c.allowedTileConnection.right);

			if (addCellToWorkItems) {
				cellsToCheckNext[`${x}-${y}`] = { x, y };
			}

			return tileAllowed;
		});

		log.debug('after filtering right constraints: ', JSON.stringify(cells));

		// Check left (west) neighbour (x-1, y)
		cells = cells.filter(c => {
			const x = workItem.x - 1;
			const y = workItem.y;
			const { tileAllowed, addCellToWorkItems } = checkCellConstraintsWithNeighbour(workingBoard, x, -1, false, y * boardSize.width + x, 'right', c.allowedTileConnection.left);

			if (addCellToWorkItems) {
				cellsToCheckNext[`${x}-${y}`] = { x, y };
			}

			return tileAllowed;
		});

		log.debug('after filtering left constraints: ', JSON.stringify(cells));

		// Check top (north) neigbour (x, y-1)
		cells = cells.filter(c => {
			const x = workItem.x;
			const y = workItem.y - 1;
			const { tileAllowed, addCellToWorkItems } = checkCellConstraintsWithNeighbour(workingBoard, y, -1, false, y * boardSize.width + x, 'bottom', c.allowedTileConnection.top);

			if (addCellToWorkItems) {
				cellsToCheckNext[`${x}-${y}`] = { x, y };
			}

			return tileAllowed;
		});

		log.debug('after filtering top constraints: ', JSON.stringify(cells));

		if (cells.length === 0) {
			log.debug('dddddddddd');
			workingBoard[cellIndex] = []; // TBD handle breakOnError here
		} else if (cells.length === 1) {
			workingBoard[cellIndex] = cells;
		} else {
			// Case: beginning [we have no collapsed neigbours yet] or we have more than one possible candidate for this cell
			const r = randomBetween(randomGenerator, 0, cells.length - 1);
			log.debug(`random value is: ${r}`, randomBetween);
			const sel = cells[r];
			workingBoard[cellIndex] = [sel];
		}

		log.debug("Cells to check next: ", JSON.stringify(cellsToCheckNext));

		return { newWorkItems: Object.values(cellsToCheckNext), paused: breakOnError && cells.length === 0 };
	}

	return [initWaveCollapse, collapseNextCell, collapseCellsUntil, collapseCellsToEnd, boardSize, board, workItems];
}