import React from 'react';

const Footer = () => {
  return <div className="border-t border-gray-300 p-2 text-center">
    <p className="text-sm">{(new Date()).getFullYear()} Tree Generator by <Link href="https://vmac.ch">vmac.ch</Link><span className="pl-2 text-gray-500">{process.env.REACT_APP_VERSION}</span><span className="pl-4">Libraries used&nbsp;</span><Link href="https://headlessui.com">HeadlessUi</Link><span>&nbsp;and icons from&nbsp;</span><Link href="https://heroicons.com">Heroicons</Link></p>
  </div>
};

const Link = (props) => {
  const {href, children} = props;
  return <a className="underline hover:text-lime-500 active:text-lime-500  visited:text-lime-700" href={href}>{children}</a>
}

export default Footer;