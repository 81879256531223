
function Button(props) {
	const { label, onClick, disabled } = props;

	return <>
		{disabled && <button disabled className="inline-flex w-full justify-center items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><div>{label}</div></button>}
		{!disabled && <button className="inline-flex w-full justify-center items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-lime-500 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-700" onClick={onClick}><div>{label}</div></button>}
	</>
};

export default Button;