
function TextButton(props) {
	const { label, onClick, disabled } = props;

	return <>
		{disabled && <button disabled className="text-stone-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"><div>{label}</div></button>}
		{!disabled && <button className="text-stone-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none hover:text-lime-700 mr-1 ease-linear transition-all duration-150" onClick={onClick}><div>{label}</div></button>}
	</>
};

export default TextButton;