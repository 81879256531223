import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";

function ManualCollapseDialogContent(props) {
    const { allTileImages, currentTiles, updateCurrentTiles } = props;

    const onToggleTile = useCallback((index) => {
        const i = currentTiles.findIndex(t => t === allTileImages[index]);

        if (i === -1) {
            updateCurrentTiles([...currentTiles, allTileImages[index]]);
        } else if (currentTiles.length > 1) {
            const copy = [...currentTiles];
            copy.splice(i, 1);
            updateCurrentTiles(copy);
        }
    }, [allTileImages, currentTiles, updateCurrentTiles]);

    return (<div>
        <p className="mb-2">Which tiles should be allowed at this position (at least one needs to be present)?</p>
        <div className="flex flex-wrap justify-start">
            {allTileImages.map(((tile, index) => <div key={tile} className="relative border my-1 mr-2" onClick={() => onToggleTile(index)}>
                <img alt={tile} src={"/assets/tiles/" + tile} />
                {currentTiles && currentTiles.find(t => t === tile) === undefined && <XMarkIcon className="absolute text-red-700" style={{ top: 0, width: '100px', height: '100px' }} aria-hidden="true" />}
            </div>))}
        </div>
    </div>);
}

export default ManualCollapseDialogContent;
