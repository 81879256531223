import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from 'react';
import Board from './Board';
import Button from './Button';
import DebugDialogContent from './DebugDialogContent';
import Footer from './Footer';
import Header from './Header';
import ManualCollapseDialogContent from './ManualCollapseDialogContent';
import SlideOut from './SlideOut';
import useSimpleWaveCollapse from './useSimpleWaveCollapse';
import { randomSeedWord } from './utils';
import Modal from "./Modal";
import TextButton from "./TextButton";

const TILESSIMPLE = [
	{ image: 'Artboard-04.png', allowedTileConnection: { top: 1, right: 0, bottom: 0, left: 1 } },
	{ image: 'Artboard-05.png', allowedTileConnection: { top: 1, right: 1, bottom: 1, left: 0 } },
	{ image: 'Artboard-06.png', allowedTileConnection: { top: 0, right: 0, bottom: 1, left: 0 } },
	{ image: 'Artboard-07.png', allowedTileConnection: { top: 0, right: 0, bottom: 1, left: 0 } },
	{ image: 'Artboard-12.png', allowedTileConnection: { top: 1, right: 0, bottom: 0, left: 0 } },
	{ image: 'Artboard-13.png', allowedTileConnection: { top: 1, right: 0, bottom: 0, left: 0 } },
	{ image: 'Artboard-14.png', allowedTileConnection: { top: 0, right: 0, bottom: 1, left: 0 } },
	{ image: 'Artboard-15.png', allowedTileConnection: { top: 0, right: 0, bottom: 1, left: 0 } },
	{ image: 'Artboard-00.png', allowedTileConnection: { top: 0, right: 0, bottom: 0, left: 0 } }, // Empty tile
	{ image: 'Artboard-11.png', allowedTileConnection: { top: 1, right: 1, bottom: 1, left: 1 } }, // Cross
	{ image: 'Artboard-10.png', allowedTileConnection: { top: 1, right: 1, bottom: 0, left: 0 } }, // Wurzel with right split
	{ image: 'Artboard-03.png', allowedTileConnection: { top: 1, right: 0, bottom: 1, left: 1 } }, // ?
	{ image: 'Artboard-02.png', allowedTileConnection: { top: 0, right: 0, bottom: 0, left: 1 } }, // End part right
	{ image: 'Artboard-99.png', allowedTileConnection: { top: 0, right: 1, bottom: 0, left: 0 } }, // End part right
	{ image: 'Artboard-98.png', allowedTileConnection: { top: 0, right: 1, bottom: 1, left: 0 } }, // End part right
	{ image: 'Artboard-97.png', allowedTileConnection: { top: 0, right: 0, bottom: 1, left: 1 } }, // End part right
];

const START_POSITION_COLOR = 'rgba(251, 191, 36, 0.7)';
const RUN_TO_POSITION_COLOR = 'rgba(220, 38, 38, 0.7)';

function App() {
	//const width = 15;
	//const height = 5;
	const allTileImages = TILESSIMPLE.map(item => item.image);

	const [customBoardSize, setCustomBoardSize] = useState({ width: 15, height: 5 });
	const [placeholderBoard, setPlaceholderBoard] = useState(() => {
		let placeholderBoard = [];
		for (let y = 0; y < customBoardSize.height; y++) {
			for (let x = 0; x < customBoardSize.width; x++) {
				placeholderBoard.push(allTileImages);
			}
		}
		return placeholderBoard;
	});

	const logger = {
		debug: (message) => { console.debug(`D: ${message}`) },
		info: (message) => { console.info(`I: ${message}`) },
		error: (message) => { console.error(`E: ${message}`) },
	};

	const [initWaveCollapse, collapseNextCell, collapseCellsUntil, collapseCellsToEnd, boardSize, board, workItems] = useSimpleWaveCollapse(TILESSIMPLE, logger);


	const [debugModal, setDebugModal] = useState(false);
	const [manualCollapseDialogOpen, setManualCollapseDialogOpen] = useState(false);
	const [customCellConfig, setCustomCellConfig] = useState({});
	const [seed, setSeed] = useState(randomSeedWord());
	const [startPosition, setStartPosition] = useState({ x: Math.floor(Math.random() * customBoardSize.width), y: Math.floor(Math.random() * customBoardSize.height) });
	const [runToPosition, setRunToPosition] = useState(null);
	const [initialized, setInitialized] = useState(false);
	const [markedCells, setMarkedCells] = useState([
		{ x: startPosition.x, y: startPosition.y, color: START_POSITION_COLOR }
	]);
	const onInit = useCallback(() => {
		if (initialized) {
			return;
		}
		initWaveCollapse(customBoardSize.width, customBoardSize.height, startPosition.x, startPosition.y, seed, customCellConfig);
		setInitialized(true);
	}, [customBoardSize.height, customBoardSize.width, customCellConfig, initWaveCollapse, initialized, seed, startPosition.x, startPosition.y]);

	const onSingleStep = useCallback(() => {
		if (!initialized) {
			return;
		}

		collapseNextCell(1, false);

	}, [collapseNextCell, initialized]);

	const onRunTo = useCallback(() => {
		if (!initialized) {
			return;
		}

		collapseCellsUntil(parseInt(runToPosition.x, 10), parseInt(runToPosition.y, 10), false);

	}, [collapseCellsUntil, initialized, runToPosition]);

	const onRunToEnd = useCallback(() => {
		if (!initialized) {
			return;
		}

		collapseCellsToEnd(false);

	}, [collapseCellsToEnd, initialized]);

	const onResetBoard = useCallback(() => {
		setInitialized(false);
	}, []);

	const onCellClick = useCallback((x, y) => {
		if (initialized) {
			setRunToPosition({ x: x, y: y });
			if (startPosition) {
				setMarkedCells([
					{ x: x, y: y, color: RUN_TO_POSITION_COLOR },
					{ ...startPosition, color: START_POSITION_COLOR }
				]);
			} else {
				setMarkedCells([
					{ x: x, y: y, color: RUN_TO_POSITION_COLOR }
				]);
			}
			return;
		}

		setStartPosition({ x: x, y: y });
		if (runToPosition) {
			setMarkedCells([
				{ ...runToPosition, color: RUN_TO_POSITION_COLOR },
				{ x: x, y: y, color: START_POSITION_COLOR }
			]);
		} else {
			setMarkedCells([
				{ x: x, y: y, color: START_POSITION_COLOR }
			]);
		}
	}, [initialized, runToPosition, startPosition]);

	const [uncommitedEditedTiles, setUncommitedEditedTiles] = useState();

	const getCurrentTilesAtStartposition = useCallback(() => {
		const key = startPosition.x + '-' + startPosition.y;
		return customCellConfig[key] ? customCellConfig[key].map(ccc => ccc.image) : allTileImages;
	}, [allTileImages, customCellConfig, startPosition.x, startPosition.y]);

	const onManualCollapse = useCallback((x, y) => {
		setUncommitedEditedTiles(getCurrentTilesAtStartposition);
		setManualCollapseDialogOpen(true);
	}, [getCurrentTilesAtStartposition]);

	const onDismissManualCollapseDialogOpen = useCallback((save) => {
		setManualCollapseDialogOpen(false);

		if (!save) {
			setUncommitedEditedTiles(undefined);
			return;
		}

		const c = uncommitedEditedTiles.map(t => TILESSIMPLE.find(ts => ts.image === t));
		customCellConfig[startPosition.x + '-' + startPosition.y] = c;
		setCustomCellConfig(customCellConfig);

		let newPlaceholderBoard = [...placeholderBoard];
		newPlaceholderBoard[startPosition.y * customBoardSize.width + startPosition.x] = uncommitedEditedTiles;
		setPlaceholderBoard(newPlaceholderBoard);
		setUncommitedEditedTiles(undefined);
	}, [customBoardSize.width, customCellConfig, placeholderBoard, startPosition.x, startPosition.y, uncommitedEditedTiles]);

	const onSetRandomStartPosition = useCallback(() => {
		const x = Math.floor(Math.random() * customBoardSize.width);
		const y = Math.floor(Math.random() * customBoardSize.height);
		setStartPosition({ x: x, y: y });
		setMarkedCells([{ x: x, y: y, color: START_POSITION_COLOR }]);
	}, [customBoardSize.height, customBoardSize.width]);

	const [showChangeBoardSizeDialog, setShowChangeBoardSizeDialog] = useState(false);
	const [uncommitedBoardSize, setUncommitedBoardSize] = useState(customBoardSize);

	const onDismissChangeBoardSizeDialog = useCallback((save) => {
		setShowChangeBoardSizeDialog(false);

		if (!save) {
			setUncommitedBoardSize(customBoardSize);
			return;
		}

		const width = parseInt(uncommitedBoardSize.width, 10);
		const height = parseInt(uncommitedBoardSize.height, 10);

		setCustomBoardSize({ width: width, height: height });
		if (startPosition.x >= width) {
			setStartPosition({ x: Math.floor(Math.random() * width), y: startPosition.y });
		}

		if (startPosition.y >= height) {
			setStartPosition({ x: startPosition.x, y: Math.floor(Math.random() * height) });
		}

		let newPlaceholderBoard = [];
		for (let y = 0; y < height; y++) {
			for (let x = 0; x < width; x++) {
				newPlaceholderBoard.push(allTileImages);
			}
		}
		setPlaceholderBoard(newPlaceholderBoard);
	}, [allTileImages, customBoardSize, startPosition.x, startPosition.y, uncommitedBoardSize.height, uncommitedBoardSize.width]);

	return (
		<div className="h-full w-full flex flex-col bg-white">
			<div className="bg-lime-500 shadow-lg"><Header /></div>
			<div className="flex flex-col w-full h-full px-2 pt-2 overflow-hidden justify-center">
				<div className="min-h-0 flex-1 flex-col overflow-scroll border">
					<Board markedCells={markedCells} updateStartPos={onCellClick} width={customBoardSize.width} height={customBoardSize.height} boardData={board.length < customBoardSize.width * customBoardSize.height || !initialized ? placeholderBoard : board.map(b => b.map(c => c.image))} />
				</div>
				<div className="flex-shrink-0 justify-end">
					{!initialized && <div className="my-3 flex justify-center items-center">
						<div className="mx-1"><TextButton label="Change Board Size" onClick={() => setShowChangeBoardSizeDialog(true)} /></div>
						<div className="mr-2">Seed Value: <input className="border w-64" value={seed} onChange={(e) => setSeed(e.target.value)} /></div>
						<div className="mr-7"><Button label={<ArrowPathIcon style={{ width: '15px', height: '15px' }} aria-hidden="true" />} onClick={() => setSeed(randomSeedWord())} /></div>
						<div className="ml-auto mr-2">Starting Position: {startPosition.x},{startPosition.y} (click cell to change)</div>
						<div className="mr-7"><Button label={<ArrowPathIcon style={{ width: '15px', height: '15px' }} aria-hidden="true" />} onClick={onSetRandomStartPosition} /></div>
						<div className="mx-7"><TextButton label="Configure Cell" onClick={onManualCollapse} /></div>

						<div className="ml-auto w-20"><Button label="Init" onClick={onInit} /></div>
					</div>}
					{initialized && <div className="my-3 flex justify-center items-center">
						<div className="ml-1">Seed: {seed}</div>
						<div className="ml-auto mr-7"><Button label="Run to End" onClick={onRunToEnd} disabled={workItems.length === 0} /></div>
						{runToPosition && <div className="mx-7"><Button label={`Run to ${runToPosition.x},${runToPosition.y}`} onClick={onRunTo} disabled={workItems.length === 0 || (workItems[0].x === runToPosition.x && workItems[0].y === runToPosition.y)} /></div>}
						{!runToPosition && <div className="mx-7"><Button label={`Run to n/a`} disabled /></div>}
						<div className="mx-7"><Button label="Single Step" onClick={onSingleStep} disabled={workItems ? workItems.length === 0 : 0} /></div>
						<div className="ml-auto"><Button label="Reset Board" onClick={onResetBoard} /></div>
						<div className="ml-2"><TextButton label="Show Debug" onClick={() => setDebugModal(true)} /></div>
					</div>}

					<SlideOut title="Debug Information" openSlideOut={debugModal} dismissSlideout={() => setDebugModal(false)} hideFooter>
						<DebugDialogContent boardSize={boardSize} seed={seed} startPosition={startPosition} runToPosition={runToPosition} workItems={workItems} customCellConfig={customCellConfig} />
					</SlideOut>

					<SlideOut title={`Configure tile at ${startPosition.x},${startPosition.y}`} openSlideOut={manualCollapseDialogOpen} dismissSlideout={onDismissManualCollapseDialogOpen} >
						<ManualCollapseDialogContent allTileImages={allTileImages} currentTiles={uncommitedEditedTiles} updateCurrentTiles={setUncommitedEditedTiles} />
					</SlideOut>

					<Modal title="Change board size" openSlideOut={showChangeBoardSizeDialog} dismissSlideout={onDismissChangeBoardSizeDialog}>
						<div className="grid w-full grid-cols-6">
							<span>Width:</span><input className="col-span-5 border" value={uncommitedBoardSize.width} onChange={(e) => setUncommitedBoardSize({ width: e.target.value, height: uncommitedBoardSize.height })} />
							<span>Height:</span><input className="col-span-5 border" value={uncommitedBoardSize.height} onChange={(e) => setUncommitedBoardSize({ width: uncommitedBoardSize.width, height: e.target.value })} />
						</div>
					</Modal>
				</div>
			</div>
			<div className="bg-gray-50 mt-2"><Footer /></div>
		</div>
	);
}

export default App;
