import { stringify } from "./utils";

function DebugDialogContent(props) {
    const { boardSize, startPosition, seed, runToPosition, workItems, customCellConfig } = props;

    return (<div>
        <h2 className="mb-2 underline">General</h2>
        <div>Seed: {seed}</div>
        <div className="mr-1">Board Size: {boardSize.width} x {boardSize.height}</div>
        <div>Starting Position: {startPosition.x},{startPosition.y}</div>
        <div>Run To Position: {runToPosition ? (runToPosition.x + ',') : 'n/a'}{runToPosition && runToPosition.y}</div>
        <h2 className="mt-3 mb-2 underline">Work Items (#{workItems.length})</h2>
        <div>
            {workItems.length === 0 && <div className="italic text-stone-500">No work left for the algorithm.</div>}
            {workItems.length > 0 && <pre>[<br />{workItems.map(wi => '  ' + stringify(wi, null, ' ') + '\n')}]</pre>}
        </div>
        <h2 className="mt-3 mb-2 underline">Custom Cell Config</h2>
        <div>
            {(!customCellConfig || Object.keys(customCellConfig).length === 0) && <div className="italic text-stone-500">No custom cell configuration setup</div>}
            {customCellConfig && Object.keys(customCellConfig).map(key => <div key={key}>
                <div>{key.split('-')[0]},{key.split('-')[1]}:</div>
                {customCellConfig[key].map(config =>
                    <div key={config.image} className="inline-flex w-10 h-10 border my-1 mr-1"><img alt={config.image} src={"/assets/tiles/" + config.image} /></div>)}
            </div>)}
        </div>
    </div>);
}

export default DebugDialogContent;
