
// Pretty Print (with array on one line) source: https://stackoverflow.com/a/47285593
export const stringify = (obj, indent = 2) =>
    JSON.stringify(obj, (key, value) => {
        if (Array.isArray(value) && !value.some(x => x && typeof x === 'object')) {
            return `\uE000${JSON.stringify(value.map(v => typeof v === 'string' ? v.replace(/"/g, '\uE001') : v))}\uE000`;
        }
        return value;
    }, indent).replace(/"\uE000([^\uE000]+)\uE000"/g, match => match.substr(2, match.length - 4).replace(/\\"/g, '"').replace(/\uE001/g, '\\"'));

const verbs = ["absorb", "balance", "capture", "decorate", "embrace", "foster", "gallop", "hijack", "ignite", "juggle", "kick", "laugh", "mimic", "navigate", "organize", "paddle", "question", "relocate", "stimulate", "tackle", "unleash", "vanish", "whisper", "yawn", "zoom", "accelerate", "breathe", "collaborate", "digest", "elevate", "flaunt", "grasp", "harvest", "illuminate", "jolt", "knead", "linger", "melt", "nurture", "orchestrate", "provoke", "quicken", "revive", "sprint", "traverse", "unify", "vanquish", "whirl", "yearn", "zigzag"];
const adjectives = ["agile", "blissful", "cozy", "diligent", "enchanting", "fearless", "gigantic", "hollow", "impeccable", "jovial", "keen", "luminous", "mysterious", "noble", "optimistic", "passionate", "quirky", "resilient", "serene", "timid", "upbeat", "vibrant", "wavy", "xenial", "yielding", "zany", "ancient", "brave", "charming", "daring", "eager", "fierce", "glistening", "hopeful", "inquisitive", "jolly", "kind", "lively", "magnificent", "nifty", "open", "playful", "quiet", "radiant", "silly", "tender", "unique", "vast", "witty", "xeric", "youthful", "zealous"];
const nouns = [
    "apple", "airplane", "animal", "arrow", "art", "area", "authority", "anxiety", "admiration", "attention", "assumption", "anger", "auction", "asphalt", "act", "airport", "adventure", "alcohol", "athlete", "abyss",
    "book", "building", "business", "boy", "ball", "bird", "beach", "bag", "bank", "bed", "bread", "brain", "birth", "blue", "bathroom", "belief", "beauty", "box", "boat", "bridge",
    "car", "cat", "chair", "city", "country", "computer", "chocolate", "child", "coffee", "camera", "church", "circle", "class", "clown", "cloud", "cinema", "courage", "color", "captain", "crystal",
    "dog", "day", "door", "data", "dinner", "dream", "dance", "desk", "doctor", "diamond", "decision", "danger", "deer", "drum", "department", "drama", "darkness", "dollar", "duck", "desert",
    "eye", "ear", "egg", "elephant", "energy", "emotion", "education", "earth", "engine", "evening", "example", "elbow", "excitement", "exercise", "expectation", "error", "experience", "ease", "event", "empire",
    "family", "friend", "food", "father", "fish", "flower", "face", "film", "fire", "faith", "freedom", "fun", "fence", "foundation", "future", "form", "fame", "failure", "fashion", "flag",
    "girl", "gold", "game", "government", "group", "green", "grass", "guitar", "glass", "gas", "garden", "goal", "gun", "ghost", "gift", "garbage", "grandfather", "generation", "geography", "giggle",
    "house", "heart", "hand", "hair", "hat", "history", "hope", "hospital", "happiness", "holiday", "heaven", "honey", "height", "hard", "hole", "hip", "horror", "harmony", "hesitation", "hunger",
    "ice", "idea", "information", "industry", "island", "interest", "invitation", "insurance", "instruction", "improvement", "initiative", "illness", "insect", "imagination", "ink", "introduction", "income", "injury", "integration", "institution",
    "job", "journey", "jungle", "joke", "joy", "jacket", "jewel", "journalist", "judge", "jump", "judgment", "January", "jelly", "justice", "jet", "journeyman", "jockey", "jeans", "jingle", "jar",
    "kangaroo", "keyboard", "ketchup", "kettle", "kiosk", "kite", "kiwi", "kleenex", "knapsack", "knee", "knife", "knitting", "knob", "knock", "knot", "knowledge", "koala", "krypton", "kumquat", "kernel",
    "love", "language", "lion", "laughter", "light", "lake", "lemon", "library", "laptop", "lunch", "logic", "landscape", "lock", "license", "liver", "leather", "lioness", "leisure", "linen", "lobster",
    "milk", "money", "music", "movie", "mountain", "moon", "museum", "mirror", "mango", "medicine", "meeting", "mosquito", "mushroom", "mud", "moment", "mammal", "marriage", "mystery", "machine", "mansion",
    "nose", "name", "night", "nation", "number", "necklace", "novel", "needle", "nest", "nature", "noise", "nut", "nurse", "navy", "notebook", "nail", "network", "news", "november", "nutrition",
    "ocean", "orange", "office", "oil", "owl", "opinion", "orchestra", "oven", "oasis", "obstacle", "observation", "outfit", "organ", "ownership", "outlet", "oath", "odor", "offense", "offspring", "overcoat",
    "pizza", "pencil", "phone", "paper", "people", "piano", "park", "picture", "peace", "party", "planet", "paint", "poem", "prayer", "pillow", "panther", "passion", "pumpkin", "proposal", "professor",
    "queen", "question", "quilt", "quarrel", "quartz", "qualification", "quince", "quality", "quantity", "quay", "quokka", "quiz", "quote", "queue", "quill", "quark", "quintet", "quarry", "quiver", "quibble",
    "river", "rain", "road", "rock", "rose", "riverbed", "recipe", "room", "rhythm", "rainbow", "resistance", "reason", "robot", "reflection", "reward", "radish", "routine", "rabbit", "rage", "reality",
    "sun", "star", "sky", "song", "sea", "snake", "street", "ship", "smile", "snow", "sister", "sandwich", "sugar", "scent", "sapphire", "swim", "strength", "sword", "sailboat", "sock",
    "tree", "table", "time", "town", "tiger", "train", "telephone", "television", "teapot", "technology", "temperature", "trouble", "towel", "toothbrush", "thunder", "trumpet", "triangle", "tulip", "twilight", "trick",
    "umbrella", "uniform", "unicorn", "university", "use", "utensil", "umbilical", "usher", "underwear", "unicornfish", "ultraviolet", "urinal", "ultimate", "ugly", "usherette", "universe", "unison", "ultrasound", "ulcer", "username",
    "victory", "village", "voice", "volcano", "violin", "vehicle", "vase", "vegetable", "vacuum", "vest", "virus", "vision", "vacation", "variation", "vibration", "villain", "validation", "vault", "vanilla", "vegetarian",
    "water", "wind", "world", "woman", "waste", "wood", "wallet", "watch", "wasp", "wheel", "wave", "whistle", "weight", "wine", "wafer", "whale", "worm", "wheat", "warehouse", "wizard",
    "xylophone", "x-ray", "xenophobia", "xenon", "xylem", "xanthophyll", "x-axis", "xenogenesis", "xeric", "xenolithic", "xenophile", "xanthan", "xenogamy", "xiphoid", "xenocracy", "xerography", "xanthous", "xerophyte", "xenobiotic", "xylary",
    "yoga", "yellow", "year", "yacht", "yolk", "youth", "yesterday", "yarn", "yield", "yawn", "yogurt", "yeast", "yard", "yak", "yearbook", "yarrow", "yeshiva", "yiddish", "yeoman", "yesteryear",
    "zebra", "zoo", "zone", "zero", "zinc", "zombie", "zipper", "zucchini", "zenith", "zany", "zoology", "zeal", "zither", "zeitgeist", "zealous", "zonal", "zarzuela", "zoogeography", "zoonosis", "zoospore"
];

export const randomSeedWord = () => adjectives[Math.floor(Math.random() * adjectives.length)] + ' ' + verbs[Math.floor(Math.random() * verbs.length)] + ' ' + nouns[Math.floor(Math.random() * nouns.length)];